// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_t6 d_bD";
export var storyRowWrapper = "w_hx d_hx d_bK";
export var storyLeftWrapper = "w_t7 d_bz d_bP";
export var storyWrapperFull = "w_t8 d_cD";
export var storyWrapperFullLeft = "w_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "w_mS d_hy";
export var storyLeftWrapperCenter = "w_t9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "w_vb d_hF";
export var storyHeader = "w_vc d_hD d_w d_cs";
export var storyHeaderCenter = "w_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "w_hB d_hB d_by d_dw";
export var storyBtnWrapper = "w_vd d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "w_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "w_qH d_fg d_Z";
export var imageWrapperFull = "w_qJ d_w d_H d_bf d_Z";
export var SubtitleSmall = "w_qd q_qd q_rl q_rx";
export var SubtitleNormal = "w_qf q_qf q_rl q_ry";
export var SubtitleLarge = "w_qg q_qg q_rl q_rz";
export var textLeft = "w_dv";
export var textCenter = "w_dw";
export var textRight = "w_dx";