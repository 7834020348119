// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "r_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "r_fR d_fR d_bz d_bJ";
export var menuDesign6 = "r_sB d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "r_sC d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "r_sD d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "r_sF d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "r_sG d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "r_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "r_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "r_sH";
export var navbarItem = "r_nb d_bx";
export var navbarLogoItemWrapper = "r_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "r_sJ d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "r_sK d_gd d_by d_Z d_bs";
export var burgerToggle = "r_sL d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "r_sM d_gd d_by d_Z d_bs";
export var burgerInput = "r_sN d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "r_sP d_f3 d_w d_H";
export var burgerLine = "r_sQ d_f1";
export var burgerMenuDesign6 = "r_sR d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "r_sS d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "r_sT d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "r_sV d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "r_sW d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "r_sX d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "r_sY d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "r_sZ d_bC d_bP";
export var compact = "r_s0";
export var navDivided = "r_s1";
export var staticBurger = "r_s2";
export var menu = "r_s3";
export var navbarDividedLogo = "r_s4";
export var nav = "r_s5";
export var fixed = "r_s6";