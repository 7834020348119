// extracted by mini-css-extract-plugin
export var alignDiscLeft = "n_qT d_fp d_bG d_dv";
export var alignLeft = "n_qp d_fp d_bG d_dv";
export var alignDiscCenter = "n_qV d_fq d_bD d_dw";
export var alignCenter = "n_bP d_fq d_bD d_dw";
export var alignDiscRight = "n_qW d_fr d_bH d_dx";
export var alignRight = "n_qq d_fr d_bH d_dx";
export var footerContainer = "n_qX d_dW d_bW";
export var footerContainerFull = "n_qY d_dT d_bW";
export var footerHeader = "n_kf d_kf";
export var footerTextWrapper = "n_qZ d_w";
export var footerDisclaimerWrapper = "n_km d_km d_ck";
export var badgeWrapper = "n_q0 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "n_q1 d_bz d_bJ d_bN d_bL";
export var wide = "n_q2 d_cy";
export var right = "n_q3 d_bK";
export var line = "n_fk d_fl d_cv";
export var badgeDisclaimer = "n_q4 d_bC d_bP d_bJ";
export var badgeContainer = "n_q5 d_bz d_bH d_bP";
export var badge = "n_q6";
export var padding = "n_q7 d_c7";
export var end = "n_q8 d_bH";
export var linkWrapper = "n_q9 d_dB";
export var link = "n_mZ d_dB";
export var colWrapper = "n_rb d_cx";
export var consent = "n_f d_f d_bC d_bP";
export var disclaimer = "n_rc d_bz d_bJ";
export var media = "n_rd d_bx d_dy";
export var itemRight = "n_rf";
export var itemLeft = "n_rg";
export var itemCenter = "n_rh";
export var exceptionWeight = "n_rj q_rN";