// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_vt";
export var lbContainerInner = "z_vv";
export var movingForwards = "z_vw";
export var movingForwardsOther = "z_vx";
export var movingBackwards = "z_vy";
export var movingBackwardsOther = "z_vz";
export var lbImage = "z_vB";
export var lbOtherImage = "z_vC";
export var prevButton = "z_vD";
export var nextButton = "z_vF";
export var closing = "z_vG";
export var appear = "z_vH";