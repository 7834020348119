// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "m_qh d_bD";
export var datasheetRowWrapper = "m_lt d_lt d_bK";
export var datasheetLeftWrapper = "m_qj d_bz d_bP";
export var datasheetWrapperFull = "m_qk d_cD";
export var datasheetWrapperFullLeft = "m_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "m_mS d_lv";
export var contentWrapperCenter = "m_ql d_lz";
export var datasheetLeftWrapperCenter = "m_qm d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "m_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "m_qn d_lw";
export var datasheetFooter = "m_ly d_ly";
export var alignLeft = "m_qp d_dv";
export var alignCenter = "m_bP d_dw";
export var alignRight = "m_qq d_dx";
export var datasheetInnerWrapperNewLeft = "m_qr d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "m_qs d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "m_qt d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "m_qv d_bK";
export var datasheetSubtitle = "m_qw d_cx";
export var tableRow = "m_qx";
export var cell = "m_qy";
export var tableRowWrapper = "m_qz d_w d_dv";
export var tableHeadingCell = "m_qB";
export var tableHeading = "m_qC";
export var tableRowStriped = "m_qD";
export var tableRowHeading = "m_qF";
export var dataTable = "m_qG d_w";
export var imageWrapper = "m_qH d_fg d_Z";
export var imageWrapperFull = "m_qJ d_H d_w d_bf d_Z";
export var imageWrapperIcon = "m_qK";
export var titleMargin = "m_qL d_cs";
export var datasheetInnerInnerWrapper = "m_qM d_w";
export var hasLabels = "m_qN";
export var label = "m_qP";
export var SmallSmall = "m_qQ q_qQ q_rl q_rJ";
export var SmallNormal = "m_qR q_qR q_rl q_rK";
export var SmallLarge = "m_qS q_qS q_rl q_rG";